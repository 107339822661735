// Inspired by https://github.com/uidotdev/usehooks/blob/90fbbb4cc085e74e50c36a62a5759a40c62bb98e/index.js#L664
import { useMemo, useRef } from 'react'

type LongPressEvent = React.PointerEvent<HTMLElement>

interface LongPressOptions {
  threshold?: number
  onStart?: (event: LongPressEvent) => void
  onFinish?: (event: LongPressEvent) => void
  onCancel?: (event: LongPressEvent) => void
}

const isPointerEvent = event => {
  return event.nativeEvent instanceof PointerEvent
}
/**
 * `useLongPress` is a hook for detecting long press events on an element.
 * It can trigger a callback after a specified threshold of time and provides onStart, onFinish, and onCancel events.
 *
 * @param callback - The function to execute when a long press is detected.
 * @param options - (Optional) Configuration options including threshold time and additional event handlers.
 * @return - Binding functions for mouse and touch events to attach to the element.
 */

const useLongPress = (callback: (event: LongPressEvent) => void, options: LongPressOptions = {}) => {
  const { threshold = 400, onStart, onFinish, onCancel } = options
  const isLongPressActive = useRef(false)
  const isPressed = useRef(false)
  const timerId = useRef<NodeJS.Timeout | null>(null)

  return useMemo(() => {
    const start = (event: LongPressEvent) => {
      if(!isPointerEvent(event)) {
        return
      }
      if(onStart) {
        onStart(event)
      }
      isPressed.current = true
      timerId.current = setTimeout(() => {
        callback(event)
        isLongPressActive.current = true
        if(onFinish) {
          onFinish(event)
        }
      }, threshold)
    }

    const cancel = event => {
      if(!isPointerEvent(event)) {
        return
      }

      if(isLongPressActive.current) {
        if(onFinish) {
          onFinish(event)
        }
      } else if(isPressed.current) {
        if(onCancel) {
          onCancel(event)
        }
      }
      isLongPressActive.current = false
      isPressed.current = false
      if(timerId.current) {
        window.clearTimeout(timerId.current)
      }
    }

    return {
      onPointerDown: start,
      onPointerUp: cancel,
      onPointerLeave: cancel
    }
  }, [callback, threshold, onCancel, onFinish, onStart])
}

export default useLongPress
