import { useCallback, useEffect, useMemo } from 'react'
import { Button, Collapse } from 'reactstrap'
import { timeRecordSystemTypes } from '@evelia/common/constants'
import { appendEitherOrEmpty } from '@evelia/common/helpers'
import clsx from 'clsx'

import { EmployeeMinimalModel } from '../../api/rtk/employeesApi'
import TimeRecordStatusButton from '../../components/TimeRecords/TimeRecordStatusButton'
import useLongPress from '../../hooks/useLongPress'
import useToggle from '../../hooks/useToggle'
import { useTypedSelector } from '../../reducerTypes'
import { findCurrentTimeRecordTypeOfEmployee, getTimeRecordTypesOfEmployee } from '../../selectors/timeRecordSelectors'
import LeimaaTimeRecordTypesList from './LeimaaTimeRecordTypesList'
import { useSelectContext, useSelectUpdaterContext } from './SelectContext'
import useCreateTimeRecord from './useCreateTimeRecord'
import useLeimaaBoard from './useLeimaaBoard'

interface LeimaaEmployeeRowProps {
  employee: EmployeeMinimalModel
}

const LeimaaEmployeeRow = ({ employee }: LeimaaEmployeeRowProps) => {
  const { uuid, board } = useLeimaaBoard()
  const [isTimeRecordTypesOpen, { enable, disable, toggle }] = useToggle(false)
  const timeRecordTypes = useTypedSelector(state => getTimeRecordTypesOfEmployee(state, employee.id))

  // @ts-expect-error selectors not typed
  const currentTimeRecordType = useTypedSelector(state => findCurrentTimeRecordTypeOfEmployee(state, { employeeId: employee.id }))
  // @ts-expect-error `email` is not returned from /collaboration/timeboard route as part of employee
  const label = employee.firstName || employee.lastName ? appendEitherOrEmpty(employee.firstName, employee.lastName) : employee.email

  const nextTimeRecordTypeId = useMemo(() => currentTimeRecordType?.id === board.defaultTimeRecordTypeId
    ? timeRecordTypes.find(timeRecordType => timeRecordType.systemType === timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_AWAY)?.id
    : board.defaultTimeRecordTypeId
  , [board.defaultTimeRecordTypeId, currentTimeRecordType?.id, timeRecordTypes])

  // Multiselect
  const selections = useSelectContext()
  const updaters = useSelectUpdaterContext()

  const isSelected = selections.has(employee.id)
  const isSelections = !!selections.size
  const shouldResetMultiselect = isSelected && isTimeRecordTypesOpen
  const handleSelected = useCallback(() => {
    // Original behavior
    if(!isSelections) {
      return toggle()
    }
    // Initial employee is clicked which enabled multiselect
    if(shouldResetMultiselect) {
      updaters.reset()
      return disable()
    }
    // Toggle multiselect employees
    return isSelected
      ? updaters.removeSelection(employee.id)
      : updaters.addSelection(employee.id)
  }, [disable, employee.id, shouldResetMultiselect, isSelected, isSelections, toggle, updaters])

  useEffect(() => {
    if(!isSelected && selections.size) {
      disable()
    }
  }, [disable, isSelected, selections.size])

  const longPressProps = useLongPress(
    useCallback(() => {
      // Disable multiselect after first employee is enabled
      if(isSelections) {
        return
      }
      updaters.addSelection(employee.id)
      enable()
    }, [employee.id, enable, isSelections, updaters]),
    {
      onCancel: handleSelected
    })

  const stateProps = isSelected
    ? { color: 'primary', alertClassName: 'alert-primary' }
    : selections.size
      ? { color: 'secondary', alertClassName: 'alert-secondary' }
      : { color: 'info', alertClassName: 'alert-info' }

  const isRecordChangeDisabled = !nextTimeRecordTypeId || (!isSelected && !!selections.size)

  // Time record actions
  const createTimeRecord = useCreateTimeRecord(uuid)
  const handleCreateTimeRecord = async(timeRecordTypeId: number) => {
    const employeeIds = isSelections ? selections.values() : [employee.id]
    for(const employeeId of employeeIds) {
      await createTimeRecord(timeRecordTypeId, employeeId)
    }
    updaters.reset()
    disable()
  }

  return currentTimeRecordType
    ? (
      <>
        <div className='d-flex gap-1'>
          <Button
            data-testid='bigBrotherNameBlock'
            color={stateProps.color}
            className={clsx(
              'flex-1 py-2 my-0 text-center text-nowrap overflow-x-hidden cursor-pointer',
              `alert`,
              stateProps.alertClassName
            )}
            {...longPressProps}
          >
            {label}
          </Button>
          <div className='flex-1'>
            <TimeRecordStatusButton
              timeRecordType={currentTimeRecordType}
              className='w-100 py-2'
              testId='bigBrotherStatusButton'
              onClick={() => handleCreateTimeRecord(nextTimeRecordTypeId)}
              disabled={isRecordChangeDisabled}
            />
          </div>
        </div>
        <Collapse isOpen={isTimeRecordTypesOpen} className='bg-light border border-top-0 border-info-subtle rounded-bottom'>
          <LeimaaTimeRecordTypesList
            createTimeRecord={handleCreateTimeRecord}
            currentTimeRecordType={currentTimeRecordType}
            employeeTimeRecordTypes={timeRecordTypes}
          />
        </Collapse>
      </>
      )
    : null
}

export default LeimaaEmployeeRow
