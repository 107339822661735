import { eventChannel } from 'redux-saga'
import { all, put, select, takeEvery } from 'redux-saga/effects'

import employeeActions from '../actions/employeeActions'
import employeeRoleActions from '../actions/employeeRoleActions'
import timeRecordActions from '../actions/timeRecordActions'
import {
  leimaaBoardApi
} from '../api/leimaaBoardApi'
import { emitNewVersionNotification, watchOnVersionSockets } from '../components/NewVersion'
import {
  createFlow,
  createSocketWatcherWithApiHandlerAndNormalizer,
  fetchFlow,
  getSocketWatcherFunction
} from '../helpers/sagaHelpers'
import { leimaaBoardActions, leimaaBoardSelectors } from '../slices/leimaaBoardSlice'
import { connectLeimaaSocket } from '../socket'
import { handleTimeRecordApiResponse } from './timeRecordSaga'

const handleLeimaaBoardApiResponse = mainAction =>
  function* ({
    data,
    employees,
    timeRecords,
    timeRecordTypes,
    employeeRoles
  }) {
    yield put(employeeActions.fetchSuccess(employees))
    yield put(employeeRoleActions.fetchSuccess(employeeRoles))

    yield put(timeRecordActions.timeRecordTypes.fetchSuccess(timeRecordTypes))
    yield put(timeRecordActions.fetchSuccess(timeRecords))
    yield put(mainAction(data))
    connectLeimaaSocket(data.id)
    return data
  }

const watchOnLeimaaBoardSockets = createSocketWatcherWithApiHandlerAndNormalizer('timeRecordSite', leimaaBoardActions, handleLeimaaBoardApiResponse)

const leimaaBoardFetchFlow = fetchFlow({
  fetchApi: leimaaBoardApi.fetch,
  actions: leimaaBoardActions,
  base: 'leimaaBoards',
  errorMsg: 'työaikakirjaustaulun',
  apiResponseHandler: handleLeimaaBoardApiResponse(leimaaBoardActions.fetchSuccess)
})

const reFetchLeimaaBoardFlow = function* () {
  const currentId = yield select(state => leimaaBoardSelectors.selectIds(state)?.[0])
  if(currentId) {
    yield put(leimaaBoardActions.fetchRequest({ id: currentId, force: true }))
  }
}

const leimaaBoardCreateFlow = createFlow(leimaaBoardApi.create, leimaaBoardActions, 'Työaikakirjaus', 'Työaikakirjauksen', handleTimeRecordApiResponse(timeRecordActions.fetchSuccess))

const onVersionSocket = (emit, rev) => {
  emitNewVersionNotification(emit, rev)
}

const watchReconnect = getSocketWatcherFunction(socket => eventChannel(emit => {
  const handleReconnect = () => {
    emit(leimaaBoardActions.reFetchRequest())
  }
  socket.io.on('reconnect', handleReconnect)
  return () => {
    socket.io.off('reconnect', handleReconnect)
  }
}))

export default function* leimaaBoardSaga() {
  yield takeEvery(leimaaBoardActions.fetchRequest.type, leimaaBoardFetchFlow)
  yield takeEvery(leimaaBoardActions.createRequest.type, leimaaBoardCreateFlow)

  yield takeEvery(leimaaBoardActions.reFetchRequest, reFetchLeimaaBoardFlow)

  yield all([
    watchOnLeimaaBoardSockets(),
    watchOnVersionSockets(onVersionSocket)(),
    watchReconnect()
  ])
}
