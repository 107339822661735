import { useMemo } from 'react'
import { useParams } from 'react-router-dom-v5-compat'

import { useTypedSelector } from '../../reducerTypes'
import { leimaaBoardSelectors } from '../../slices/leimaaBoardSlice'

export type Board = {
  name: string
  defaultTimeRecordTypeId: number
  timeRecordTypeIds: number[]
}

const useLeimaaBoard = () => {
  const { uuid } = useParams<{ uuid: string }>()
  // @ts-expect-error selector expects number as id
  const board: Board = useTypedSelector(state => leimaaBoardSelectors.selectById(state, uuid))
  return useMemo(() => ({ board, uuid }), [board, uuid])
}

export default useLeimaaBoard
