import { Switch } from 'react-router'
import { CompatRoute } from 'react-router-dom-v5-compat'
import { Container, Navbar, NavbarBrand } from 'reactstrap'
import { faRefresh } from '@fortawesome/pro-regular-svg-icons'

import IconButton from '../../components/Buttons/IconButton'
import { NotFoundErrorPage } from '../../components/Errors'
import logo from '../../images/evelia-logo.svg'
import { BOARD_NAME_ID } from './BoardName'
import LeimaaTable from './LeimaaTable'

const reloadSite = () => window.location.reload()

const LeimaaMain = () => {
  return (
    <>
      <Navbar dark color='dark'>
        <NavbarBrand className='d-flex gap-2 align-items-center flex-wrap'>
          <img
            src={logo}
            alt='Evelia logo'
            className='app-logo me-2'
          />
          Leimaa | <span id={BOARD_NAME_ID} className='fs-2 my-n2' />
        </NavbarBrand>
        <IconButton
          icon={faRefresh}
          condensed
          outline
          color='link'
          className='text-secondary'
          size='md'
          onClick={reloadSite}
        >
          Päivitä
        </IconButton>
      </Navbar>
      <Container fluid='xl' className='overflow-auto py-3'>
        <Switch>
          <CompatRoute path='/:uuid' component={LeimaaTable} />
          <CompatRoute path='/' exact component={NotFoundErrorPage} />
        </Switch>
      </Container>
    </>
  )
}

export default LeimaaMain
