import {
  createEntityAdapter
} from '@reduxjs/toolkit'
import noop from 'lodash/noop'

import { createGenericSlice } from '../helpers/sliceHelpers'

const leimaaBoardAdapter = createEntityAdapter({})

const leimaaBoardSlice = createGenericSlice({
  name: 'leimaaBoard',
  entityAdapter: leimaaBoardAdapter,
  reducers: {
    reFetchRequest: noop
  }
})

export const leimaaBoardSelectors = leimaaBoardAdapter.getSelectors(state => state.leimaaBoards)
export const leimaaBoardActions = leimaaBoardSlice.actions

export default leimaaBoardSlice
