import { createPortal } from 'react-dom'

export const BOARD_NAME_ID = '_board-name'

interface BoardNameProps {
  name?: string
}
/**
 * Display board name on navbar. Use portal because `:uuid` route is not always available
 */
const BoardName = ({ name }: BoardNameProps) => {
  const boardNameElement = document.getElementById(BOARD_NAME_ID)
  return boardNameElement
    ? createPortal(name, boardNameElement)
    : null
}

export default BoardName
