import 'react-app-polyfill/stable'

import ReactDOM from 'react-dom/client'
import { configureStore } from '@reduxjs/toolkit'
import { createBrowserHistory } from 'history'
import { createReduxHistoryContext } from 'redux-first-history'
import createSagaMiddleware from 'redux-saga'

import 'typeface-ibm-plex-sans'
import 'typeface-open-sans'
import 'typeface-roboto-condensed'
import '../src/styles/index.scss'
import LeimaaApp from '../src/containers/Leimaa/LeimaaApp'
import createRootReducer from './rootReducer'
import rootSaga from './rootSaga'

const history = createBrowserHistory()
const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history
})
const sagaMiddleware = createSagaMiddleware()
const middleware = [
  sagaMiddleware,
  routerMiddleware
]

const store = configureStore({
  reducer: createRootReducer(routerReducer),
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: {
      ignoredActionPaths: ['data.resolve', 'data.reject', 'payload.resolve', 'payload.reject']
    }
  }).concat(middleware)
})

sagaMiddleware.run(rootSaga)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <LeimaaApp store={store} history={createReduxHistory(store)} />
)
