import { createApi, defaultEmbeddedNormalizer } from '../helpers/apiHelpers'
import { parseFilterParams } from '../helpers/reducerHelpers'
import { optionsQueryBase } from './urlTemplates'

export const leimaaBoardApi = createApi({
  base: 'collaboration/timeboard',
  normalizer: defaultEmbeddedNormalizer,
  extraUrlTemplate: optionsQueryBase,
  editGetParams: parseFilterParams
})
