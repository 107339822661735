import { Fade } from 'reactstrap'
import { faArrowRotateBack } from '@fortawesome/free-solid-svg-icons'

import IconButton from '../../components/Buttons/IconButton'
import { useSelectContext, useSelectUpdaterContext } from './SelectContext'

const MultiselectReset = () => {
  const selections = useSelectContext()
  const updaters = useSelectUpdaterContext()

  return (
    <Fade in={!!selections.size} className='position-absolute top-0 end-0'>
      <b className='text-muted pointer-events-none'>Monivalinta käytössä </b>
      <IconButton
        onClick={updaters.reset}
        icon={faArrowRotateBack}
        condensed
        disabled={!selections.size}
      >
        Peruuta
      </IconButton>
    </Fade>
  )
}

export default MultiselectReset
