import { combineReducers } from 'redux'

import employees from '../src/reducers/employeeReducer'
import employeeRoles from '../src/reducers/employeeRoleReducer.js'
import withHistory from '../src/reducers/historyReducer'
import timeRecords from '../src/reducers/timeRecordReducer'
import leimaaBoardSlice from '../src/slices/leimaaBoardSlice'

const reducer = routerReducer => combineReducers({
  employees,
  employeeRoles,
  timeRecords,
  leimaaBoards: leimaaBoardSlice.reducer,
  router: withHistory(routerReducer)
})

const rootReducer = routerReducer => reducer(routerReducer)

export default rootReducer
