import { useEffect } from 'react'
import groupBy from 'lodash/groupBy'

import useFetch from '../../hooks/useFetch'
import { useTypedSelector } from '../../reducerTypes'
import { findEmployees } from '../../selectors/employeeSelectors'
import { leimaaBoardActions } from '../../slices/leimaaBoardSlice'
import BoardName from './BoardName'
import LeimaaEmployeeRoleSection from './LeimaaEmployeeRoleSection'
import { generateDynamicManifestData } from './manifestHelpers'
import MultiselectReset from './MultiselectReset'
import { SelectProvider } from './SelectContext'
import useLeimaaBoard from './useLeimaaBoard'

const LeimaaTable = () => {
  const { board, uuid } = useLeimaaBoard()
  useEffect(() => {
    if(board) {
      generateDynamicManifestData(board)
    }
  }, [board])
  useFetch(leimaaBoardActions.fetchRequest, { data: { id: uuid }, force: true })
  const employees = useTypedSelector(state => findEmployees(state, { isNotInTimeRecords: false }))
  const employeesByGroup = groupBy(employees, 'primaryEmployeeRoleId')

  return (
    <SelectProvider>
      <div className='d-flex flex-column gap-2 position-relative'>
        <MultiselectReset />
        <BoardName name={board?.name} />
        {Object.entries(employeesByGroup)
          .map(([groupId, employeesOfGroup]) => (
            <LeimaaEmployeeRoleSection
              key={groupId}
              employees={employeesOfGroup}
              employeeRoleId={Number(groupId) || null}
            />
          )
          )}
      </div>
    </SelectProvider>
  )
}

export default LeimaaTable
