import { useCallback } from 'react'
import { Col, Row } from 'reactstrap'

import { EmployeeMinimalModel } from '../../api/rtk/employeesApi'
import useLongPress from '../../hooks/useLongPress'
import { useTypedSelector } from '../../reducerTypes'
import { findEmployeeRoleWithId } from '../../selectors/employeeRoleSelectors'
import LeimaaEmployeeRow from './LeimaaEmployeeRow'
import { useSelectUpdaterContext } from './SelectContext'

const useSelectAllEmployees = (employees: EmployeeMinimalModel[]) => {
  const updaters = useSelectUpdaterContext()
  return useCallback(() => {
    for(const employee of employees) {
      updaters.addSelection(employee.id)
    }
  }, [employees, updaters])
}

interface LeimaaEmployeeRoleSectionProps {
  employees: EmployeeMinimalModel[]
  employeeRoleId: number | null
}

const LeimaaEmployeeRoleSection = ({
  employees,
  employeeRoleId
}: LeimaaEmployeeRoleSectionProps) => {
  // @ts-expect-error selectors are not typed
  const employeeRole = useTypedSelector(state => findEmployeeRoleWithId(state, employeeRoleId))
  const selectAllEmployees = useSelectAllEmployees(employees)
  const longPressProps = useLongPress(selectAllEmployees)
  return (
    <div>
      <h3 {...longPressProps}>
        {employeeRole?.name ?? 'Muut'}
      </h3>
      <Row
        xs={1}
        sm={2}
        md={3}
        className='gx-2 row-gap-4'
      >
        {employees.map(employee => (
          <Col key={employee.id}>
            <LeimaaEmployeeRow employee={employee} />
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default LeimaaEmployeeRoleSection
