import { useCallback } from 'react'

import { promisifyToolkitAction } from '../../helpers/dispatchHelpers'
import useActions from '../../hooks/useActions'
import { leimaaBoardActions } from '../../slices/leimaaBoardSlice'

const componentActions = {
  createTimeRecord: leimaaBoardActions.createRequest
}

const useCreateTimeRecord = (uuid: string | undefined, closeTimeRecordTypes?: () => void) => {
  const actions = useActions(componentActions)
  return useCallback(async(timeRecordTypeId: number, employeeId: number) => {
    const response = await promisifyToolkitAction({ timeRecordTypeId, employeeId }, actions.createTimeRecord, { id: uuid, subItem: 'timerecord' })
    closeTimeRecordTypes?.()
    return response
  }
  , [closeTimeRecordTypes, actions.createTimeRecord, uuid])
}

export default useCreateTimeRecord
