import PropTypes from 'prop-types'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { CompatRouter } from 'react-router-dom-v5-compat'
import { Flip, ToastContainer } from 'react-toastify'

import LeimaaMain from './LeimaaMain'

const LeimaaApp = ({ store, history }) => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <CompatRouter>
          <HelmetProvider>
            <LeimaaMain />
            <ToastContainer theme='colored' transition={Flip} closeOnClick />
          </HelmetProvider>
        </CompatRouter>
      </Router>
    </Provider>
  )
}

LeimaaApp.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default LeimaaApp
